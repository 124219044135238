import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, Radio, Button, Rate, Checkbox, DatePicker, Space, Tag, Input,
} from 'antd';
import moment from 'moment';
import Loader from '../../../common/components/Loader';
import { getProjectFilterAction, getProjectFilterForCountryAction } from './logic';

const { Option } = Select;
const apiBasedFilters = ['analysis_seq_id', 'query_seq_id', 'priority', 'indications', 'countries', 'primary_center_types', 'compliance', 'networks', 'alliance_type', 'originating_party', 'file_name', 'p_tier', 'alliance_member_name', 'version', 'alliance_contact', 'parexel_contact', 'min_max_metric'];
const recordTypeOptions = [
  {
    label: 'Principal investigator',
    value: 'Principal investigator',
  },
  {
    label: 'Primary center',
    value: 'Primary organization',
  },
  {
    label: 'Parent institute',
    value: 'Parent institute',
  }];
const reliabilityOptions = ['Class 1', 'Class 2', 'Class 3'];

const ProjectFilters = (props) => {
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState({
    alliance: 'All',
    is_added: 'All',
    is_added_by_sponsor: 'All',
    is_added_by_lcor: 'All',
    is_commented: 'All',
    quality_rating: undefined,
    tier: undefined,
    p_tier: undefined,
  });
  const defaultFilters = {
    alliance: 'All',
    is_added: 'All',
    is_added_by_sponsor: 'All',
    is_added_by_lcor: 'All',
    is_commented: 'All',
    status: [],
    lcor_status: [],
    sponsor_status: [],
  };

  const resetSpecificFilter = (key) => {
    const tempFilters = { ...selectedFilters, [key]: defaultFilters[key] };
    if (key === 'date_added') {
      if (startDateForFilter !== '' && endDateForFilter !== '') {
        setStartDateForFilter('')
        setEndDateForFilter('')
      } else if (endDateForFilter !== '') {
        setEndDateForFilter('')
      } else {
        setStartDateForFilter('')
      }
    }
    setSelectedFilters(tempFilters);
    setErrorMessageForIndustryStudies('')
    setErrorMessageForPatientEnrolled('')
    setErrorMessageForCompetingStudies('')
    setErrorMessageForPxlStudies('')
    setErrorMessageForStartupFactor('')
    setErrorMessageForRecruitmentFactor('')
    setMaxOverallStartUpMonths('')
  };

  const renderLabel = (key) => {
    if (key === 'analysis_seq_id') {
      return 'Analysis ID'
    } if (key === 'query_seq_id') {
      return 'Query ID'
    } if (key === 'priority') {
      return 'Priority'
    } if (key === 'indications') {
      return 'Indications'
    } if (key === 'countries') {
      return 'Country'
    } if (key === 'compliance') {
      return 'Compliance issue'
    } if (key === 'networks') {
      return 'Network'
    } if (key === 'reliability_class') {
      return 'Reliability'
    } if (key === 'alliance_type') {
      return 'Alliance type'
    } if (key === 'primary_center_types') {
      return 'Organization type'
    } if (key === 'is_added') {
      return 'Manually added'
    } if (key === 'alliance') {
      return 'Alliance'
    } if (key === 'is_added_by_sponsor') {
      return 'Added from sponsors list'
    } if (key === 'is_added_by_lcor') {
      return 'Added from LCORs list'
    } if (key === 'is_commented') {
      return 'With comments'
    } if (key === 'originating_party') {
      return 'Originating Party'
    } if (key === 'version') {
      return 'Versions'
    } if (key === 'alliance_member_name') {
      return 'Alliance Member Name'
    } if (key === 'quality_rating') {
      return 'Quality rating of'
    } if (key === 'tier') {
      return 'Tier (Search Specific)'
    } if (key === 'p_tier') {
      return 'Profile Tier(General)'
    } if (key === 'industry_studies') {
      return '# Industrial studies by'
    } if (key === 'enrolled_count') {
      return '# Patients enrolled by'
    } if (key === 'pxl_studies') {
      return '# PXL studies by'
    } if (key === 'start_up_factor') {
      return 'Start-up Factor'
    } if (key === 'recruitment_factor') {
      return 'Recruitment Factor'
    } if (key === 'start_up_months') {
      return 'Start up in months'
    } if (key === 'date_added') {
      return 'Date added'
    } if (key === 'cls_kol_score_class') {
      return 'KOL Score class'
    } if (key === 'emerging_tag') {
      return 'Emerging KOL class'
    } if (key === 'file_name') {
      return 'Uploaded File name'
    } if (key === 'competing_studies') {
      return '# Competing studies by'
    } if (key === 'alliance_contact') {
      return 'Alliance Contact'
    } if (key === 'parexel_contact') {
      return 'Parexel Contact'
    } if (key === 'veeva_ready_flag_updated') {
      return 'Veeva Ready Status'
    }
    return ''
  }

  const renderTags = () => {
    return Object.keys(selectedFilters).map((key) => {
      const value = selectedFilters[key];
      if (Array.isArray(value) && value.length > 0) {
        return (
          <Tag
            key={key}
            closable
            onClose={() => resetSpecificFilter(key)}
            style={{ margin: '4px' }}
          >
            {renderLabel(key)}
          </Tag>
        );
      } if (value && value !== defaultFilters[key] && !(Array.isArray(value) && value.length === 0)) {
        return (
          <Tag
            key={key}
            closable
            onClose={() => resetSpecificFilter(key)}
            style={{ margin: '4px' }}
          >
            {renderLabel(key)}
          </Tag>
        );
      }
      return null;
    }).filter((tag) => tag !== null);
  };

  const [selectedRecordType, setSelectedRecordType] = useState('Principal investigator');
  const [disabledQualityStars, setDisableQualityStars] = useState(false);
  const runAnalysisFilters = useSelector((state) => state.projectFilters);
  const [dateFilter, setDateFilter] = useState(undefined)
  const [dateError, setDateError] = useState(false)
  const [startDateForFilter, setStartDateForFilter] = useState('');
  const [endDateForFilter, setEndDateForFilter] = useState('');
  const [country, setCountry] = useState([])
  const getProjectFilterForCountryResponse = useSelector((reduxState) => reduxState.getProjectFilterForCountryResponse || {})

  const [errorMessageForIndustryStudies, setErrorMessageForIndustryStudies] = useState('')
  const [errorMessageForPatientEnrolled, setErrorMessageForPatientEnrolled] = useState('');
  const [errorMessageForCompetingStudies, setErrorMessageForCompetingStudies] = useState('');
  const [errorMessageForPxlStudies, setErrorMessageForPxlStudies] = useState('');
  const [errorMessageForStartupFactor, setErrorMessageForStartupFactor] = useState('');
  const [errorMessageForRecruitmentFactor, setErrorMessageForRecruitmentFactor] = useState('');
  const [errorMessageForStartUpMonth, setErrorMessageForStartUpMonth] = useState('');

  const [minOverallIndustryStudies, setMinOverallIndustryStudies] = useState(0)
  const [maxOverallIndustryStudies, setMaxOverallIndustryStudies] = useState(0)

  const [minOverallPatientEnrolled, setMinOverallPatientEnrolled] = useState(0)
  const [maxOverallPatientEnrolled, setMaxOverallPatientEnrolled] = useState(0)

  const [minOverallCompetingStudies, setMinOverallCompetingStudies] = useState(0)
  const [maxOverallCompetingStudies, setMaxOverallCompetingStudies] = useState(0)

  const [minOverallPxlStudies, setMinOverallPxlStudies] = useState(0)
  const [maxOverallPxlStudies, setMaxOverallPxlStudies] = useState(0)

  const [minOverallStartUpFactor, setMinOverallStartUpFactor] = useState(0)
  const [maxOverallStartUpFactor, setMaxOverallStartUpFactor] = useState(0)

  const [minOverallRecruitmentFactor, setMinOverallRecruitmentFactor] = useState(0)
  const [maxOverallRecruitmentFactor, setMaxOverallRecruitmentFactor] = useState(0)

  const [minOverallStartUpMonths, setMinOverallStartUpMonths] = useState(0)
  const [maxOverallStartUpMonths, setMaxOverallStartUpMonths] = useState(0)

  useEffect(() => {
    apiBasedFilters.forEach((type) => {
      dispatch(getProjectFilterAction({
        project_id: props.analysisId,
        type,
      }, {
        filter_type: type,
        key: type,
      }))
    })
    dispatch(getProjectFilterForCountryAction({
      project_id: props.analysisId,
    }))
  }, []);

  useEffect(() => {
    if (runAnalysisFilters.flag) {
      setMinOverallIndustryStudies(runAnalysisFilters?.data?.min_max_metric?.min_industry_studies)
      setMaxOverallIndustryStudies(runAnalysisFilters?.data?.min_max_metric?.max_industry_studies)

      setMinOverallPatientEnrolled(runAnalysisFilters?.data?.min_max_metric?.min_enrolled_count)
      setMaxOverallPatientEnrolled(runAnalysisFilters?.data?.min_max_metric?.max_enrolled_count)

      setMinOverallCompetingStudies(runAnalysisFilters?.data?.min_max_metric?.min_competing_studies)
      setMaxOverallCompetingStudies(runAnalysisFilters?.data?.min_max_metric?.max_competing_studies)

      setMinOverallPxlStudies(runAnalysisFilters?.data?.min_max_metric?.min_pxl_studies)
      setMaxOverallPxlStudies(runAnalysisFilters?.data?.min_max_metric?.max_pxl_studies)

      setMinOverallStartUpFactor(runAnalysisFilters?.data?.min_max_metric?.min_start_up_factor)
      setMaxOverallStartUpFactor(runAnalysisFilters?.data?.min_max_metric?.max_start_up_factor)

      setMinOverallRecruitmentFactor(runAnalysisFilters?.data?.min_max_metric?.min_recruitment_factor)
      setMaxOverallRecruitmentFactor(runAnalysisFilters?.data?.min_max_metric?.max_recruitment_factor)

      setMinOverallStartUpMonths(runAnalysisFilters?.data?.min_max_metric?.min_start_up_months)
      setMaxOverallStartUpMonths(runAnalysisFilters?.data?.min_max_metric?.max_start_up_months)
    }
  }, [JSON.stringify(runAnalysisFilters)])

  useEffect(() => {
    if (getProjectFilterForCountryResponse.flag) {
      if (getProjectFilterForCountryResponse?.data?.data?.length) {
        const countryField = getProjectFilterForCountryResponse?.data?.data?.filter((itm) => itm.status === 'Shortlisted')
        setCountry(countryField.map((itm) => itm.country))
      }
    }
  }, [getProjectFilterForCountryResponse])

  useEffect(() => {
    const tempFilters = { ...selectedFilters };
    tempFilters.date_added = dateFilter;
    setSelectedFilters(tempFilters)
  }, [dateFilter])

  useEffect(() => {
    setSelectedFilters(props.appliedFilters);
  }, [JSON.stringify(props.appliedFilters)])

  const handleRecordTypeChange = (val) => {
    setSelectedRecordType(val);
  }

  const onRadioFilterChange = (e, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = e.target.value;
    setSelectedFilters(tempFilters);
  }

  const onClearClick = () => {
    setSelectedFilters({
      alliance: 'All',
      is_added: 'All',
      is_added_by_sponsor: 'All',
      is_added_by_lcor: 'All',
      is_commented: 'All',
      quality_rating: undefined,
      tier: undefined,
    });
    setDateFilter(undefined);
    setStartDateForFilter('');
    setEndDateForFilter('');
    props.onReset();
  }

  const handleChange = (value, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = value;
    setSelectedFilters(tempFilters);
  }

  const onQualityRatingChange = (newValue) => {
    if (newValue.target && newValue.target.checked) {
      setDisableQualityStars(true);
    } else {
      setDisableQualityStars(false);
    }
    const tempFilters = { ...selectedFilters };
    tempFilters.quality_rating = newValue.target && newValue.target.checked ? 0 : newValue;
    setSelectedFilters(tempFilters);
  }

  const onTierChange = (newValue) => {
    const tempFilters = { ...selectedFilters };
    tempFilters.tier = newValue;
    setSelectedFilters(tempFilters);
  }

  const handleDateChange = (e, type) => {
    setDateError(false)
    if (type === 'start_date') {
      setStartDateForFilter(e)
      if (e === null) {
        if (dateFilter[1]) {
          setDateFilter([0, dateFilter[1]])
        } else {
          setDateFilter(undefined)
        }
      } else {
        const startDate = moment(e._d).unix();
        if (dateFilter !== undefined) {
          if (dateFilter[1]) {
            setDateFilter([
              parseInt(startDate / 86400, 10) * 86400,
              dateFilter[1],
            ])
          } else {
            setDateFilter([
              parseInt(startDate / 86400, 10) * 86400,
            ])
          }
        } else {
          setDateFilter([
            parseInt(startDate / 86400, 10) * 86400,
          ])
        }
        if (type === 'start_date' && dateFilter[1]) {
          if (startDate > dateFilter[1]) {
            setDateError(true)
          }
        }
      }
    } else if (type === 'end_date') {
      setEndDateForFilter(e)
      if (e === null) {
        if ((dateFilter[0] && dateFilter[0] !== null) || (dateFilter[0] && dateFilter[0] !== undefined) || (dateFilter[0] && dateFilter[0] !== 0)) {
          setDateFilter([dateFilter[0]])
        } else {
          setDateFilter(undefined)
        }
      } else {
        const endDate = moment(e._d).unix();
        if (dateFilter !== undefined) {
          setDateFilter([
            dateFilter[0],
            parseInt(endDate / 86400, 10) * 86400 + 86399,
          ])
        } else {
          setDateFilter([
            0,
            parseInt(endDate / 86400, 10) * 86400 + 86399,
          ])
        }
        if (type === 'end_date' && dateFilter[0]) {
          if (dateFilter[0] > endDate) {
            setDateError(true)
          }
        }
      }
    }
  }

  const validateDecimalInput = (value) => {
    const regex = /^\d+(\.\d{0,2})?$/;
    return regex.test(value) || value === '';
  };

  const handleCountChange = (e, type) => {
    const value = parseFloat(e.target.value);
    if (type === 'lower') {
      const currentUpper = selectedFilters.enrolled_count ? selectedFilters.enrolled_count[1] : maxOverallPatientEnrolled;

      if (!validateDecimalInput(value)) {
        setErrorMessageForPatientEnrolled('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          enrolled_count: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallPatientEnrolled || value > maxOverallPatientEnrolled) {
          setErrorMessageForPatientEnrolled(`Value must be between ${minOverallPatientEnrolled} and ${maxOverallPatientEnrolled}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForPatientEnrolled('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [value, currentUpper],
          }));
        } else {
          setErrorMessageForPatientEnrolled('');
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForPatientEnrolled('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          enrolled_count: [value, currentUpper],
        }));
      }
    } else if (type === 'upper') {
      const currentLower = selectedFilters.enrolled_count ? selectedFilters.enrolled_count[0] : minOverallPatientEnrolled;

      if (!validateDecimalInput(value)) {
        setErrorMessageForPatientEnrolled('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          enrolled_count: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForPatientEnrolled('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [currentLower, value],
          }));
        } else if (value < minOverallPatientEnrolled || value > maxOverallPatientEnrolled) {
          setErrorMessageForPatientEnrolled(`Value must be between ${minOverallPatientEnrolled} and ${maxOverallPatientEnrolled}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [currentLower, value],
          }));
        } else {
          setErrorMessageForPatientEnrolled('');
          setSelectedFilters((prev) => ({
            ...prev,
            enrolled_count: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForPatientEnrolled('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          enrolled_count: [currentLower, value],
        }));
      }
    } else if (type === 'studieslower') {
      const currentUpper = selectedFilters.industry_studies ? selectedFilters.industry_studies[1] : maxOverallIndustryStudies;
      if (!validateDecimalInput(value)) {
        setErrorMessageForIndustryStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          industry_studies: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallIndustryStudies || value > maxOverallIndustryStudies) {
          setErrorMessageForIndustryStudies(`Value must be between ${minOverallIndustryStudies} and ${maxOverallIndustryStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForIndustryStudies('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [value, currentUpper],
          }));
        } else {
          setErrorMessageForIndustryStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForIndustryStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          industry_studies: [value, currentUpper],
        }));
      }
    } else if (type === 'studiesupper') {
      const currentLower = selectedFilters.industry_studies ? selectedFilters.industry_studies[0] : minOverallIndustryStudies;
      if (!validateDecimalInput(value)) {
        setErrorMessageForIndustryStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          industry_studies: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForIndustryStudies('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [currentLower, value],
          }));
        } else if (value < minOverallIndustryStudies || value > maxOverallIndustryStudies) {
          setErrorMessageForIndustryStudies(`Value must be between ${minOverallIndustryStudies} and ${maxOverallIndustryStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [currentLower, value],
          }));
        } else {
          setErrorMessageForIndustryStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            industry_studies: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForIndustryStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          industry_studies: [currentLower, value],
        }));
      }
    } else if (type === 'competingupper') {
      const currentLower = selectedFilters.competing_studies ? selectedFilters.competing_studies[0] : minOverallCompetingStudies;
      if (!validateDecimalInput(value)) {
        setErrorMessageForCompetingStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          competing_studies: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForCompetingStudies('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [currentLower, value],
          }));
        } else if (value < minOverallCompetingStudies || value > maxOverallCompetingStudies) {
          setErrorMessageForCompetingStudies(`Value must be between ${minOverallCompetingStudies} and ${maxOverallCompetingStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [currentLower, value],
          }));
        } else {
          setErrorMessageForCompetingStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForCompetingStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          competing_studies: [currentLower, value],
        }));
      }
    } else if (type === 'competinglower') {
      const currentUpper = selectedFilters.competing_studies ? selectedFilters.competing_studies[1] : maxOverallCompetingStudies;

      if (!validateDecimalInput(value)) {
        setErrorMessageForCompetingStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          competing_studies: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallCompetingStudies || value > maxOverallCompetingStudies) {
          setErrorMessageForCompetingStudies(`Value must be between ${minOverallCompetingStudies} and ${maxOverallCompetingStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForCompetingStudies('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [value, currentUpper],
          }));
        } else {
          setErrorMessageForCompetingStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            competing_studies: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForCompetingStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          competing_studies: [value, currentUpper],
        }));
      }
    } else
    if (type === 'pxlstudieslower') {
      const currentUpper = selectedFilters.pxl_studies ? selectedFilters.pxl_studies[1] : maxOverallPxlStudies;
      if (!validateDecimalInput(value)) {
        setErrorMessageForPxlStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          pxl_studies: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallPxlStudies || value > maxOverallPxlStudies) {
          setErrorMessageForPxlStudies(`Value must be between ${minOverallPxlStudies} and ${maxOverallPxlStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForPxlStudies('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [value, currentUpper],
          }));
        } else {
          setErrorMessageForPxlStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForPxlStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          pxl_studies: [value, currentUpper],
        }));
      }
    } else if (type === 'pxlstudiesupper') {
      const currentLower = selectedFilters.pxl_studies ? selectedFilters.pxl_studies[0] : minOverallPxlStudies;
      if (!validateDecimalInput(value)) {
        setErrorMessageForPxlStudies('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          pxl_studies: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForPxlStudies('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [currentLower, value],
          }));
        } else if (value < minOverallPxlStudies || value > maxOverallPxlStudies) {
          setErrorMessageForPxlStudies(`Value must be between ${minOverallPxlStudies} and ${maxOverallPxlStudies}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [currentLower, value],
          }));
        } else {
          setErrorMessageForPxlStudies('');
          setSelectedFilters((prev) => ({
            ...prev,
            pxl_studies: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForPxlStudies('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          pxl_studies: [currentLower, value],
        }));
      }
    } else if (type === 'startuplower') {
      const currentUpper = selectedFilters.start_up_factor ? selectedFilters.start_up_factor[1] : maxOverallStartUpFactor;
      if (!validateDecimalInput(value)) {
        setErrorMessageForStartupFactor('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_factor: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallStartUpFactor || value > maxOverallStartUpFactor) {
          setErrorMessageForStartupFactor(`Value must be between ${minOverallStartUpFactor} and ${maxOverallStartUpFactor}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForStartupFactor('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [value, currentUpper],
          }));
        } else {
          setErrorMessageForStartupFactor('');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForStartupFactor('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_factor: [value, currentUpper],
        }));
      }
    } else if (type === 'startupupper') {
      const currentLower = selectedFilters.start_up_factor ? selectedFilters.start_up_factor[0] : minOverallStartUpFactor;
      if (!validateDecimalInput(value)) {
        setErrorMessageForStartupFactor('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_factor: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForStartupFactor('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [currentLower, value],
          }));
        } else if (value < minOverallStartUpFactor || value > maxOverallStartUpFactor) {
          setErrorMessageForStartupFactor(`Value must be between ${minOverallStartUpFactor} and ${maxOverallStartUpFactor}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [currentLower, value],
          }));
        } else {
          setErrorMessageForStartupFactor('');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_factor: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForStartupFactor('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_factor: [currentLower, value],
        }));
      }
    } else if (type === 'startupmonthslower') {
      const currentUpper = selectedFilters.start_up_months ? selectedFilters.start_up_months[1] : maxOverallStartUpMonths;
      if (!validateDecimalInput(value)) {
        setErrorMessageForStartUpMonth('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_months: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallStartUpMonths || value > maxOverallStartUpMonths) {
          setErrorMessageForStartUpMonth(`Value must be between ${minOverallPxlStudies} and ${maxOverallStartUpMonths}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForStartUpMonth('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [value, currentUpper],
          }));
        } else {
          setErrorMessageForStartUpMonth('');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForStartUpMonth('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_months: [value, currentUpper],
        }));
      }
    } else if (type === 'startupmonthsupper') {
      const currentLower = selectedFilters.start_up_months ? selectedFilters.start_up_months[0] : minOverallStartUpMonths;
      if (!validateDecimalInput(value)) {
        setErrorMessageForStartUpMonth('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_months: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForStartUpMonth('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [currentLower, value],
          }));
        } else if (value < minOverallStartUpMonths || value > maxOverallStartUpMonths) {
          setErrorMessageForStartUpMonth(`Value must be between ${minOverallStartUpMonths} and ${maxOverallStartUpMonths}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [currentLower, value],
          }));
        } else {
          setErrorMessageForStartUpMonth('');
          setSelectedFilters((prev) => ({
            ...prev,
            start_up_months: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForStartUpMonth('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          start_up_months: [currentLower, value],
        }));
      }
    } else if (type === 'recruitmentfactorlower') {
      const currentUpper = selectedFilters.recruitment_factor ? selectedFilters.recruitment_factor[1] : maxOverallRecruitmentFactor;
      if (!validateDecimalInput(value)) {
        setErrorMessageForRecruitmentFactor('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          recruitment_factor: [value, currentUpper],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < minOverallRecruitmentFactor || value > maxOverallRecruitmentFactor) {
          setErrorMessageForRecruitmentFactor(`Value must be between ${minOverallRecruitmentFactor} and ${maxOverallRecruitmentFactor}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [value, currentUpper],
          }));
        } else if (value > currentUpper) {
          setErrorMessageForRecruitmentFactor('Minimum value must be lower than maximum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [value, currentUpper],
          }));
        } else {
          setErrorMessageForRecruitmentFactor('');
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [value, currentUpper],
          }));
        }
      } else {
        setErrorMessageForRecruitmentFactor('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          recruitment_factor: [value, currentUpper],
        }));
      }
    } else if (type === 'recruitmentfactorupper') {
      const currentLower = selectedFilters.recruitment_factor ? selectedFilters.recruitment_factor[0] : minOverallRecruitmentFactor;
      if (!validateDecimalInput(value)) {
        setErrorMessageForRecruitmentFactor('Please enter a valid number with up to 2 decimal places.');
        setSelectedFilters((prev) => ({
          ...prev,
          recruitment_factor: [currentLower, value],
        }));
        return;
      }
      if (!Number.isNaN(value)) {
        if (value < currentLower) {
          setErrorMessageForRecruitmentFactor('Maximum value must be greater than minimum value.');
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [currentLower, value],
          }));
        } else if (value < minOverallRecruitmentFactor || value > maxOverallRecruitmentFactor) {
          setErrorMessageForRecruitmentFactor(`Value must be between ${minOverallRecruitmentFactor} and ${maxOverallRecruitmentFactor}.`);
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [currentLower, value],
          }));
        } else {
          setErrorMessageForRecruitmentFactor('');
          setSelectedFilters((prev) => ({
            ...prev,
            recruitment_factor: [currentLower, value],
          }));
        }
      } else {
        setErrorMessageForRecruitmentFactor('Please enter a valid number.');
        setSelectedFilters((prev) => ({
          ...prev,
          recruitment_factor: [currentLower, value],
        }));
      }
    }
  }

  const applyDisabled = () => {
    if (errorMessageForIndustryStudies || errorMessageForPatientEnrolled || errorMessageForCompetingStudies || errorMessageForPxlStudies || errorMessageForStartupFactor || errorMessageForRecruitmentFactor || errorMessageForStartUpMonth) {
      return true
    }
    return false
  }

  return (
    <div className="filter-runanalysis-popover-inside">
      <div className="tag-container">
        <Space>
          {renderTags()}
        </Space>
      </div>
      <Loader loading={Object.keys(runAnalysisFilters.data).length !== 16 && runAnalysisFilters.loading} error={runAnalysisFilters.error}>
        {runAnalysisFilters.flag && Object.keys(runAnalysisFilters.data).length === 17
          ? (
            <div className="analysis-filter-popover">
              <div className="analysis-filter-section">
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Analysis ID</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search analysis ID"
                      onChange={(val) => handleChange(val, 'analysis_seq_id')}
                      maxTagCount={1}
                      value={selectedFilters?.analysis_seq_id || []}
                    >
                      {runAnalysisFilters?.data?.analysis_seq_id?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Query ID</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search query ID"
                      onChange={(val) => handleChange(val, 'query_seq_id')}
                      maxTagCount={1}
                      value={selectedFilters?.query_seq_id || []}
                    >
                      {runAnalysisFilters?.data?.query_seq_id?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Priority</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Select Priority"
                      onChange={(val) => handleChange(val, 'priority')}
                      maxTagCount={1}
                      value={selectedFilters?.priority || []}
                    >
                      {runAnalysisFilters?.data?.priority?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Indications</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search indications"
                      onChange={(val) => handleChange(val, 'indications')}
                      maxTagCount={1}
                      value={selectedFilters?.indications || []}
                    >
                      {runAnalysisFilters?.data?.indications?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Country</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search countries"
                      onChange={(val) => handleChange(val, 'countries')}
                      maxTagCount={1}
                      value={selectedFilters?.countries || []}
                      optionLabelProp="label"
                      dropdownMatchSelectWidth={false}
                    >
                      {runAnalysisFilters?.data?.countries.map((itm) => (
                        <Option
                          key={itm.category}
                          value={itm.category}
                          label={itm.category}
                        >
                          <div className="option-for-shortlisted-country">
                            <div>{itm.category}</div>
                            <div>{country.includes(itm.category) ? '(Shortlisted)' : ''}</div>
                          </div>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Compliance issue</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search compliance issues"
                      onChange={(val) => handleChange(val, 'compliance')}
                      maxTagCount={1}
                      value={selectedFilters?.compliance || []}
                    >
                      {runAnalysisFilters?.data?.compliance?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Network</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search networks"
                      onChange={(val) => handleChange(val, 'networks')}
                      maxTagCount={1}
                      value={selectedFilters?.networks || []}
                    >
                      {runAnalysisFilters?.data?.networks?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-filter">
                      <div className="analysis-filter-section-filter-select">
                        <div className="filter-value-title">Reliability</div>
                        <Select
                          mode="multiple"
                          allowClear
                          placeholder="Search reliability"
                          onChange={(val) => handleChange(val, 'reliability_class')}
                          maxTagCount={1}
                          value={selectedFilters?.reliability_class || []}
                        >
                          {reliabilityOptions.map((filterItm, index) => (
                            <Option key={index} value={filterItm}>
                              {filterItm}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  )
                  : null}
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Alliance type</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search alliance types"
                      onChange={(val) => handleChange(val, 'alliance_type')}
                      maxTagCount={1}
                      value={selectedFilters?.alliance_type || []}
                    >
                      {runAnalysisFilters?.data?.alliance_type?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">Alliance</div>
                    <div className="radio-btn-multiple">
                      <Radio.Group value={selectedFilters?.alliance} onChange={(e) => onRadioFilterChange(e, 'alliance')}>
                        <Radio value="All">All</Radio>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-select">
                    <div className="filter-value-title">Organization type</div>
                    <Select
                      mode="multiple"
                      allowClear
                      placeholder="Search Organization Type"
                      onChange={(val) => handleChange(val, 'primary_center_types')}
                      maxTagCount={1}
                      value={selectedFilters?.primary_center_types || []}
                    >
                      {runAnalysisFilters?.data?.primary_center_types?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <div className="analysis-filter-section-filter">
                      <div className="analysis-filter-section-filter-radio">
                        <div className="filter-value-title">Manually added</div>
                        <div className="radio-btn-multiple">
                          <Radio.Group value={selectedFilters?.is_added} onChange={(e) => onRadioFilterChange(e, 'is_added')}>
                            <Radio value="All">All</Radio>
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </div>
                      </div>
                    </div>
                  )
                  : null}
                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <>
                      <div className="analysis-filter-section-filter">
                        <div className="analysis-filter-section-filter-radio">
                          <div className="filter-value-title">Added from sponsor&apos;s list</div>
                          <div className="radio-btn-multiple">
                            <Radio.Group value={selectedFilters.is_added_by_sponsor} onChange={(e) => onRadioFilterChange(e, 'is_added_by_sponsor')}>
                              <Radio value="All">All</Radio>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                      <div className="analysis-filter-section-filter">
                        <div className="analysis-filter-section-filter-radio">
                          <div className="filter-value-title">Added from LCOR&apos;s list</div>
                          <div className="radio-btn-multiple">
                            <Radio.Group value={selectedFilters.is_added_by_lcor} onChange={(e) => onRadioFilterChange(e, 'is_added_by_lcor')}>
                              <Radio value="All">All</Radio>
                              <Radio value="Yes">Yes</Radio>
                              <Radio value="No">No</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  : null}
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">With comments</div>
                    <div className="radio-btn-multiple">
                      <Radio.Group value={selectedFilters?.is_commented} onChange={(e) => onRadioFilterChange(e, 'is_commented')}>
                        <Radio value="All">All</Radio>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">Originating Party</div>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '150px' }}
                      placeholder="Search Originating Party"
                      onChange={(val) => handleChange(val, 'originating_party')}
                      maxTagCount={1}
                      value={selectedFilters?.originating_party || []}
                    >
                      {runAnalysisFilters?.data?.originating_party?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="analysis-filter-section-filter">
                  <div className="analysis-filter-section-filter-radio">
                    <div className="filter-value-title">Uploaded File name</div>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '150px' }}
                      placeholder="Search File name"
                      onChange={(val) => handleChange(val, 'file_name')}
                      maxTagCount={1}
                      value={selectedFilters?.file_name || []}
                    >
                      {runAnalysisFilters?.data?.file_name?.map((filterItm, index) => (
                        <Option key={index} value={filterItm.key}>
                          {filterItm.category}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>

                {props.pageName === 'savedAnalysisResults'
                  ? (
                    <>
                      <div className="analysis-filter-section-combine  analysis-filter-section-combine-rating">
                        <div className="analysis-filter-section-filter">
                          <div className="analysis-filter-section-filter-radio">
                            <div className="filter-value-title">Versions</div>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '150px' }}
                              placeholder="Search Version"
                              onChange={(val) => handleChange(val, 'version')}
                              maxTagCount={1}
                              value={selectedFilters?.version || []}
                            >
                              {runAnalysisFilters?.data?.version?.map((filterItm, index) => (
                                <Option key={index} value={filterItm.key}>
                                  {filterItm.category}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="analysis-filter-section-filter">
                          <div className="analysis-filter-section-filter-radio">
                            <div className="filter-value-title">Alliance Member Name</div>
                            <Select
                              mode="multiple"
                              allowClear
                              style={{ width: '150px' }}
                              placeholder="Select Alliance Member Name"
                              onChange={(val) => handleChange(val, 'alliance_member_name')}
                              maxTagCount={1}
                              value={selectedFilters?.alliance_member_name || []}
                            >
                              {runAnalysisFilters?.data?.alliance_member_name?.map((filterItm, index) => (
                                <Option key={index} value={filterItm.key}>
                                  {filterItm.category}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="analysis-filter-section-filter">
                          <div className="analysis-filter-section-filter-select">
                            <div className="rang-filter-wrap">
                              <div className="rang-filter-wrap-title">
                                <div className="filter-value-title">Quality rating of</div>
                                <div className="record-type-wrap">
                                  <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                                    {recordTypeOptions.map((recordType, index) => (
                                      <Option value={recordType.value} key={index} disabled={index !== 0}>
                                        {recordType.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                              <div className="range-filter-wrap range-filter-wrap-combine">
                                <Rate
                                  disabled={disabledQualityStars}
                                  count={3}
                                  defaultValue={selectedFilters?.quality_rating ? selectedFilters.quality_rating : 0}
                                  value={selectedFilters?.quality_rating ? selectedFilters.quality_rating : 0}
                                  onChange={onQualityRatingChange}
                                />
                                <span className="or-sepration">OR</span>
                                <div className="filter-input-chkbx">
                                  <Checkbox onChange={onQualityRatingChange}>Unknown</Checkbox>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="analysis-filter-section-combine  analysis-filter-section-combine-rating">
                        <div className="analysis-filter-section-filter">
                          <div className="analysis-filter-section-filter-select">
                            <div className="rang-filter-wrap">
                              <div className="rang-filter-wrap-title">
                                <div className="filter-value-title">Tier (Search Specific)</div>
                                <div className="record-type-wrap">
                                  <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                                    {recordTypeOptions.map((recordType, index) => (
                                      <Option value={recordType.value} key={index} disabled={index !== 0}>
                                        {recordType.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                              <div className="range-filter-wrap range-filter-wrap-combine">
                                <Select mode="multiple" value={selectedFilters?.tier ? selectedFilters.tier : []} onChange={onTierChange}>
                                  <Option value={0}>0</Option>
                                  <Option value={1}>1</Option>
                                  <Option value={2}>2</Option>
                                  <Option value={3}>3</Option>
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="analysis-filter-section-filter">
                          <div className="analysis-filter-section-filter-select">
                            <div className="rang-filter-wrap">
                              <div className="rang-filter-wrap-title">
                                <div className="filter-value-title">Profile Tier(General)</div>
                                <div className="record-type-wrap">
                                  <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                                    {recordTypeOptions.map((recordType, index) => (
                                      <Option value={recordType.value} key={index} disabled={index !== 0}>
                                        {recordType.label}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              </div>
                              <div className="range-filter-wrap range-filter-wrap-combine">
                                <Select
                                  mode="multiple"
                                  allowClear
                                  placeholder="Search Profile Tier"
                                  onChange={(val) => handleChange(val, 'p_tier')}
                                  maxTagCount={1}
                                  value={selectedFilters?.p_tier || []}
                                >
                                  {runAnalysisFilters?.data?.p_tier?.map((filterItm, index) => (
                                    <Option key={index} value={filterItm.key}>
                                      {filterItm.category}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  : null}
                <div className="analysis-filter-section-combine">
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># Industrial studies by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.industry_studies_default ? props.industry_studies_default[0] : 0}
                            // max={props.industry_studies_default[1]}
                            value={selectedFilters?.industry_studies ? selectedFilters.industry_studies[0] : minOverallIndustryStudies}
                            onChange={(e) => handleCountChange(e, 'studieslower')}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.industry_studies_default[0]}
                            // max={props.industry_studies_default[1] || 1000}
                            value={selectedFilters?.industry_studies ? selectedFilters.industry_studies[1] : maxOverallIndustryStudies}
                            onChange={(e) => handleCountChange(e, 'studiesupper')}
                          />
                        </div>
                        {errorMessageForIndustryStudies && <div className="error-message">{errorMessageForIndustryStudies}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># Patients enrolled by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.enrolled_count_default ? props.enrolled_count_default[0] : 0}
                            // max={props.enrolled_count_default[1]}
                            onChange={(e) => handleCountChange(e, 'lower')}
                            value={selectedFilters?.enrolled_count ? selectedFilters.enrolled_count[0] : minOverallPatientEnrolled}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.enrolled_count_default[0]}
                            // max={props.enrolled_count_default[1] || 100000}
                            onChange={(e) => handleCountChange(e, 'upper')}
                            value={selectedFilters?.enrolled_count ? selectedFilters.enrolled_count[1] : maxOverallPatientEnrolled}
                          />
                        </div>
                        {errorMessageForPatientEnrolled && <div className="error-message">{errorMessageForPatientEnrolled}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="analysis-filter-section-combine">
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># Competing studies by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.competing_studies_default ? props.competing_studies_default[0] : 0}
                            // max={props.competing_studies_default[1]}
                            value={selectedFilters?.competing_studies ? selectedFilters.competing_studies[0] : minOverallCompetingStudies}
                            onChange={(e) => handleCountChange(e, 'competinglower')}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.competing_studies_default[0]}
                            // max={props.competing_studies_default[1] || 1000}
                            value={selectedFilters?.competing_studies ? selectedFilters.competing_studies[1] : maxOverallCompetingStudies}
                            onChange={(e) => handleCountChange(e, 'competingupper')}
                          />
                        </div>
                        {errorMessageForCompetingStudies && <div className="error-message">{errorMessageForCompetingStudies}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title"># PXL studies by</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            value={selectedFilters?.pxl_studies ? selectedFilters.pxl_studies[0] : minOverallPxlStudies}
                            onChange={(e) => handleCountChange(e, 'pxlstudieslower')}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // value={inputValue}
                            value={selectedFilters?.pxl_studies ? selectedFilters.pxl_studies[1] : maxOverallPxlStudies}
                            onChange={(e) => handleCountChange(e, 'pxlstudiesupper')}
                          />
                        </div>
                        {errorMessageForPxlStudies && <div className="error-message">{errorMessageForPxlStudies}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="analysis-filter-section-combine">
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title">Start-up Factor</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.start_up_factor_default ? props.start_up_factor_default[0] : 0}
                            // max={props.start_up_factor_default[1]}
                            value={selectedFilters?.start_up_factor ? selectedFilters.start_up_factor[0] : minOverallStartUpFactor}
                            onChange={(e) => handleCountChange(e, 'startuplower')}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.start_up_factor_default[0]}
                            // max={props.start_up_factor_default[1] || 1000}
                            value={selectedFilters?.start_up_factor ? selectedFilters.start_up_factor[1] : maxOverallStartUpFactor}
                            onChange={(e) => handleCountChange(e, 'startupupper')}
                          />
                        </div>
                        {errorMessageForStartupFactor && <div className="error-message">{errorMessageForStartupFactor}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title">Recruitment Factor</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.recruitment_factor_default ? props.recruitment_factor_default[0] : 0}
                            // max={props.recruitment_factor_default[1]}
                            onChange={(e) => handleCountChange(e, 'recruitmentfactorlower')}
                            value={selectedFilters?.recruitment_factor ? selectedFilters.recruitment_factor[0] : minOverallRecruitmentFactor}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.recruitment_factor_default[0]}
                            // max={props.recruitment_factor_default[1] || 100000}
                            onChange={(e) => handleCountChange(e, 'recruitmentfactorupper')}
                            value={selectedFilters?.recruitment_factor ? selectedFilters.recruitment_factor[1] : maxOverallRecruitmentFactor}
                          />
                        </div>
                        {errorMessageForRecruitmentFactor && <div className="error-message">{errorMessageForRecruitmentFactor}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="analysis-filter-section-combine">
                  <div className="analysis-filter-section-filter">
                    <div className="analysis-filter-section-filter-select">
                      <div className="rang-filter-wrap">
                        <div className="rang-filter-wrap-title">
                          <div className="filter-value-title">Start up in months</div>
                          <div className="record-type-wrap">
                            <Select defaultValue={selectedRecordType} style={{ width: 120 }} onChange={handleRecordTypeChange}>
                              {recordTypeOptions.map((recordType, index) => (
                                <Option value={recordType.value} key={index} disabled={index !== 0}>
                                  {recordType.label}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </div>
                        <div className="range-input-wrap">
                          <Input
                            type="number"
                            // min={props.start_up_months_default ? props.start_up_months_default[0] : 0}
                            // max={props.start_up_months_default[1]}
                            value={selectedFilters?.start_up_months ? selectedFilters.start_up_months[0] : minOverallStartUpMonths}
                            onChange={(e) => handleCountChange(e, 'startupmonthslower')}
                          />
                          <span className="input-separator" />
                          <Input
                            type="number"
                            // min={props.start_up_months_default[0]}
                            // max={props.start_up_months_default[1] || 1000}
                            value={selectedFilters?.start_up_months ? selectedFilters.start_up_months[1] : maxOverallStartUpMonths}
                            onChange={(e) => handleCountChange(e, 'startupmonthsupper')}
                          />
                        </div>
                        {errorMessageForStartUpMonth && <div className="error-message">{errorMessageForStartUpMonth}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="analysis-filter-section-filter" style={{ display: 'flex' }}>
                    <div className="analysis-filter-section-filter-radio" style={{ paddingRight: '45px' }}>
                      <div className="filter-value-title">Alliance Contact </div>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '150px' }}
                        placeholder="Select alliance contact"
                        onChange={(val) => handleChange(val, 'alliance_contact')}
                        maxTagCount={1}
                        value={selectedFilters.alliance_contact || []}
                      >
                        {runAnalysisFilters?.data?.alliance_contact?.map((filterItm, index) => (
                          <Option key={index} value={filterItm.key}>
                            {filterItm.category}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="analysis-filter-section-filter-radio">
                      <div className="filter-value-title">Parexel Contact </div>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '150px' }}
                        placeholder="Select parexel contact"
                        onChange={(val) => handleChange(val, 'parexel_contact')}
                        maxTagCount={1}
                        value={selectedFilters.parexel_contact || []}
                      >
                        {runAnalysisFilters?.data?.parexel_contact?.map((filterItm, index) => (
                          <Option key={index} value={filterItm.key}>
                            {filterItm.category}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="analysis-filter-section-filter" style={{ width: '60%' }}>
                  <div className="analysis-filter-section-filter-select">
                    <div className="rang-filter-wrap">
                      <div className="rang-filter-wrap-title">
                        <div className="filter-value-title">Date Added</div>
                      </div>
                      <div className="range-input-wrap" style={{ display: 'flex' }}>
                        <div className="date-filter-start-date-label" style={{ paddingRight: '10px' }}>
                          From Date
                        </div>
                        <div className="date-filter-start-date-label" style={{ paddingRight: '10px' }}>
                          <DatePicker
                          // disabled={disableDates()}
                            placeholder="Start Date"
                            onChange={(e) => handleDateChange(e, 'start_date')}
                            value={startDateForFilter}
                          />
                        </div>
                        <span className="input-separator" />
                        <div className="date-filter-start-date" style={{ display: 'flex' }}>
                          <div className="date-filter-start-date-label" style={{ paddingRight: '10px' }}>
                            To Date
                          </div>
                          <div className="date-filter-start-date-label" style={{ paddingRight: '10px' }}>
                            <DatePicker
                        // disabled={disableDates()}
                              value={endDateForFilter}
                              placeholder="End Date"
                              onChange={(e) => handleDateChange(e, 'end_date')}
                            />
                            {dateError && <div className="error-msg">End date cannot be earlier than the start date</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="analysis-filter-section-filter emerging-investigator">
                  <div className="filter-value-title">KOL Score class </div>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '200px' }}
                    placeholder="Select emerging kol class"
                    onChange={(val) => handleChange(val, 'cls_kol_score_class')}
                    value={selectedFilters.cls_kol_score_class || []}
                  >
                    <Option value="High">High</Option> <Option value="Medium">Moderate</Option> <Option value="Low">Low</Option>
                  </Select>
                </div>
                <div className="analysis-filter-section-filter emerging-investigator">
                  <div className="filter-value-title">Emerging KOL class </div>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '200px' }}
                    placeholder="Select emerging kol class"
                    onChange={(val) => handleChange(val, 'emerging_tag')}
                    // maxTagCount={1}
                    value={selectedFilters.emerging_tag || []}
                  >
                    <Option value="High">Rising</Option> <Option value="Medium">Stable</Option> <Option value="Low">Falling</Option>
                    <Option value="Not Applicable">Not Applicable</Option>
                  </Select>
                </div>
                <div className="analysis-filter-section-filter emerging-investigator">
                  <div className="filter-value-title">Veeva Ready Status </div>
                  <Select
                    allowClear
                    style={{ width: '200px' }}
                    placeholder="Select veeva ready status"
                    onChange={(val) => handleChange(val, 'veeva_ready_flag_updated')}
                    // maxTagCount={1}
                    value={selectedFilters.veeva_ready_flag_updated || []}
                  >
                    <Option value="Yes">Yes</Option> <Option value="No">No</Option> <Option value="pending review">Pending Review</Option>
                  </Select>
                </div>
              </div>
              <div className="filter-submit-btns">
                <Button className="clear-btn" onClick={() => onClearClick()}>Clear</Button>
                <Button
                  className="apply-btn"
                  type="primary"
                  onClick={() => props.onApplyFilters(selectedFilters)}
                  disabled={applyDisabled()}
                >Apply
                </Button>
              </div>
            </div>
          )
          : null}
      </Loader>
    </div>
  )
}

export default ProjectFilters;
